import React from "react";
import { Link } from "react-router-dom";
import "./learn.css";
import { RWebShare } from "react-web-share";
import AuthService from "../../services/auth.service";
import { useEffect } from "react";
import { useState } from "react";
import Certificate from "../../assets/images/certificate.png";
import CertService from "../../services/cert.service";
import Loading from "../../assets/images/Loading.gif";

const Achievements = () => {
  const [allCerts, setAllCerts] = useState([]);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    const data = {
      uid: user.user,
      cid: "",
    };

    CertService.getCertBycond(data)
      .then((resp) => {
        setAllCerts(resp.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const downloadFile = (filePath, fileName) => {
    fetch("https://lmsaero.aeonsoftware.net/" + filePath, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  return (
    <>
      <div className="learn">
        <div className="outer-box learn achieve mb-3">
          <div className="cflex">
            <div className="heading">
              <h1>Certificate and Achievements</h1>
            </div>
          </div>
          <div className="certificate-grid">
            {Array.isArray(allCerts) ? (
              allCerts.map((allCert, index) => {
                return (
                  <>
                    {allCerts.length > 0 ? (
                      <>
                        <div className="inner-box" key={index}>
                          <div className="certificate">
                            <div className="cer-img-bg">
                              <div className="cer-img">
                                <img src={Certificate} alt="" />
                              </div>
                              <div className="achive-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18.518"
                                  height="24.146"
                                  viewBox="0 0 18.518 24.146"
                                >
                                  <g
                                    id="Group_11460"
                                    data-name="Group 11460"
                                    transform="translate(-402.003 -359.94)"
                                  >
                                    <path
                                      id="Path_11649"
                                      data-name="Path 11649"
                                      d="M411.258,359.94A9.257,9.257,0,1,1,402,369.207,9.262,9.262,0,0,1,411.258,359.94Zm0,5.01a.816.816,0,0,0-.753.5c-.269.549-.543,1.1-.81,1.645a.316.316,0,0,1-.276.208c-.615.078-1.227.176-1.84.267a.83.83,0,0,0-.446,1.457c.421.409.834.827,1.264,1.225a.427.427,0,0,1,.139.442c-.1.568-.192,1.14-.3,1.708a.82.82,0,0,0,.322.888.839.839,0,0,0,.948.023c.48-.256.972-.493,1.441-.767a.562.562,0,0,1,.641.006c.486.279.99.529,1.488.787a.8.8,0,0,0,.9-.056.786.786,0,0,0,.319-.819c-.1-.586-.194-1.173-.3-1.758a.446.446,0,0,1,.149-.463c.412-.381.807-.781,1.209-1.174.042-.041.085-.081.124-.125a.813.813,0,0,0-.441-1.355c-.634-.118-1.274-.2-1.913-.287a.337.337,0,0,1-.293-.222c-.265-.551-.539-1.1-.81-1.645A.81.81,0,0,0,411.255,364.95Z"
                                      transform="translate(0 0)"
                                      fill="#e3b023"
                                    />
                                    <path
                                      id="Path_11650"
                                      data-name="Path 11650"
                                      d="M421.5,628.5a10.993,10.993,0,0,0,5.254,2.046c-.035.094-.056.162-.086.227-.509,1.092-1.024,2.182-1.527,3.277a.825.825,0,0,1-1.575.007c-.248-.539-.512-1.07-.777-1.623-.553.141-1.094.283-1.637.414a1.932,1.932,0,0,1-.518.075.757.757,0,0,1-.7-.978C420.427,630.8,420.969,629.668,421.5,628.5Z"
                                      transform="translate(-16.688 -250.551)"
                                      fill="#e3b023"
                                    />
                                    <path
                                      id="Path_11651"
                                      data-name="Path 11651"
                                      d="M562.871,628.674c.044.081.078.137.105.2.463.994.922,1.99,1.391,2.981a.821.821,0,0,1-.987,1.2c-.582-.155-1.167-.3-1.775-.454l-.567,1.174c-.087.181-.173.362-.261.543a.807.807,0,0,1-1.484-.014l-1.348-2.89-.325-.7A10.989,10.989,0,0,0,562.871,628.674Z"
                                      transform="translate(-145.179 -250.709)"
                                      fill="#e3b023"
                                    />
                                  </g>
                                </svg>
                              </div>
                            </div>
                            <div className="cer-content">
                              <span className="coursename">
                                {allCert.course_name}
                              </span>
                              <span>issued on {allCert.awarded_on}</span>
                            </div>
                            <div className="cer-act">
                              {allCert.certfile ? (
                                <>
                                  <button
                                    className="cert-down"
                                    onClick={() =>
                                      downloadFile(
                                        `cit/${allCert.certfile}`,
                                        `${allCert.full_name}.pdf`
                                      )
                                    }
                                  >
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14.2"
                                        height="15.119"
                                        viewBox="0 0 14.2 15.119"
                                      >
                                        <g
                                          id="download"
                                          transform="translate(-15.9 511.769)"
                                        >
                                          <path
                                            id="Path_13771"
                                            data-name="Path 13771"
                                            d="M131.132-511.734a.5.5,0,0,0-.248.263c-.024.056-.033,1.065-.033,3.2v3.117h-.982c-.9,0-1,.006-1.106.056a.418.418,0,0,0-.263.42.367.367,0,0,0,.1.3c.056.071.837.967,1.736,1.993,1.121,1.283,1.665,1.881,1.733,1.916a.465.465,0,0,0,.4,0c.068-.035.612-.633,1.733-1.916.9-1.026,1.68-1.922,1.736-1.993a.36.36,0,0,0,.1-.3.347.347,0,0,0-.092-.284c-.145-.177-.183-.183-1.275-.186l-.985,0-.006-3.161c-.009-3.143-.009-3.164-.071-3.244a.807.807,0,0,0-.142-.142c-.077-.059-.121-.062-1.165-.068A5.711,5.711,0,0,0,131.132-511.734Z"
                                            transform="translate(-109.27 0)"
                                          />
                                          <path
                                            id="Path_13772"
                                            data-name="Path 13772"
                                            d="M15.909-158l.009,2,.077.166a.946.946,0,0,0,.467.467l.166.077H29.373l.166-.077a.946.946,0,0,0,.467-.467l.077-.166.009-2,.009-2H28.2v2.839H17.8V-160H15.9Z"
                                            transform="translate(0 -341.366)"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </button>
                                </>
                              ) : (
                                <></>
                              )}
                              <RWebShare
                                data={{
                                  url: `https://lmsaero.aeonsoftware.net/cit/${allCert.certfile}`,
                                }}
                              >
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14.162"
                                    height="15.118"
                                    viewBox="0 0 14.162 15.118"
                                  >
                                    <g
                                      id="share"
                                      transform="translate(-16.5 511.644)"
                                    >
                                      <path
                                        id="Path_13770"
                                        data-name="Path 13770"
                                        d="M27.538-511.61a2.693,2.693,0,0,0-1.9,1.449,2.2,2.2,0,0,0-.26,1.168,1.826,1.826,0,0,0,.08.692c.062.21.071.269.041.287s-1.009.609-2.2,1.325l-2.156,1.3-.192-.177a2.606,2.606,0,0,0-2.976-.453,2.719,2.719,0,0,0-1.408,1.715,3.155,3.155,0,0,0,0,1.331,2.662,2.662,0,0,0,3.718,1.727,2.551,2.551,0,0,0,.816-.615l.216-.228,2.07,1.059c1.136.583,2.073,1.068,2.079,1.074a.866.866,0,0,1-.041.228,2.985,2.985,0,0,0,.015,1.222,2.741,2.741,0,0,0,1.911,1.911,3.155,3.155,0,0,0,1.331,0A2.719,2.719,0,0,0,30.4-498a2.2,2.2,0,0,0,.26-1.168,2.2,2.2,0,0,0-.26-1.168,2.689,2.689,0,0,0-1.213-1.213,2.623,2.623,0,0,0-3.1.571l-.216.234-2.07-1.062c-1.139-.586-2.079-1.071-2.088-1.083a.933.933,0,0,1,.038-.287,2.511,2.511,0,0,0-.038-1.109,2.852,2.852,0,0,1-.068-.293c0-.062.225-.207,2.171-1.378l2.174-1.31.2.192a2.6,2.6,0,0,0,2.99.467,2.719,2.719,0,0,0,1.408-1.716,2.135,2.135,0,0,0,.065-.665,2.2,2.2,0,0,0-.26-1.168,2.708,2.708,0,0,0-1.716-1.4A3.128,3.128,0,0,0,27.538-511.61Z"
                                        transform="translate(0 0)"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </RWebShare>
                              <Link></Link>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="loading">
                          <h1>
                            You have not received any certificate yet. Please
                            complete the course to get the certificate.
                          </h1>
                          <img src={Loading} width="200px" alt="" />
                        </div>
                      </>
                    )}
                  </>
                );
              })
            ) : (
              <>
                <div className="loading">
                  <h1>No Certificates Received yet</h1>
                  <img src={Loading} width="200px" alt="" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Achievements;
