import React, { useEffect, useState, useRef } from "react";
import "./CourseManagement.css";
import { Form, FloatingLabel, Modal, Button, Alert } from "react-bootstrap";
import Select from "react-select";
import AuthService from "../../../services/auth.service";
import CourseService from "../../../services/course.service";
import CourseConditions from "./CourseConditions";
import JSZip from "jszip";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ColorRing } from "react-loader-spinner";

const UploadCourse = ({ prevStep, nextStep, cid }) => {
  const [uploaded, setUploaded] = useState(false);
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [show, setShow] = useState(false);
  const [actid, setActid] = useState("");

  const [delActid, setDelActid] = useState("");

  const [deleteCourseAct, setDeleteCourseAct] = useState(false);

  const [editCourseAct, setEditCourseAct] = useState(false);

  const handleDeleteClose = () => setDeleteCourseAct(false);

  const [courseAct, setCourseAct] = useState({
    actid: "",
    activity_name: "",
  });

  const [courseActPos, setCourseActPos] = useState([
    { usp: "", courseId: "", activityId: "", newPosition: "" },
  ]);

  const handleDeleteShow = (e) => {
    e.preventDefault();
    setDeleteCourseAct(true);
    setDelActid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const [scromUploading, setScromUploading] = useState(false);
  const [mediaUploading, setMediaUploading] = useState(false);

  const [validated, setValidated] = useState(false);

  const [courseActivities, setCourseActivities] = useState([]);

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
    setActid(e.currentTarget.getAttribute("data-bs-target"));
  };

  useEffect(() => {
    if (cid) {
      getCourseActivities();
    }
  }, []);

  const getCourseActivities = () => {
    CourseService.getCourseActivity(cid)
      .then((response) => {
        setCourseActivities(response.data);

        const user = AuthService.getCurrentUser();

        const updatedCourseActPos = response.data.map((activity, index) => ({
          usp: user.user,
          courseId: activity.course_id,
          activityId: activity.id,
          newPosition: index + 1,
        }));

        setCourseActPos(updatedCourseActPos);
      })
      .catch((e) => console.log(e));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseAct({ ...courseAct, [name]: value });
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...courseActivities];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setCourseActivities(copyListItems);

    const updatedCourseActPos = copyListItems.map((activity, index) => ({
      courseId: activity.course_id,
      activityId: activity.id,
      newPosition: index + 1,
    }));

    setCourseActPos(updatedCourseActPos);
  };

  const uploadScrom = async (e) => {
    // const { name } = e.target;
    const user = AuthService.getCurrentUser();
    const scromup = e.target.files[0];
    const corsid = cid;
    const userid = user.user;
    const formData = new FormData();

    // formData.append("scromup", scromup);
    // formData.append("corsid", corsid);
    // formData.append("userid", userid);

    if (scromup) {
      if (
        scromup.type === "application/zip" ||
        scromup.type === "application/x-zip-compressed"
      ) {
        try {
          const zip = await JSZip.loadAsync(scromup);
          const storyHtmlFile = zip.file("launcher.html");

          if (storyHtmlFile) {
            formData.append("scromup", scromup);
            formData.append("corsid", corsid);
            formData.append("userid", userid);
            setScromUploading(true);

            CourseService.uploadScrom(formData)
              .then((response) => {
                if (response.data == true) {
                  setScromUploading(false);
                  // setMessage("Scrom uploaded successfully!");

                  toast.success("Scrom uploaded successfully!", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });

                  CourseService.getCourseActivity(cid)
                    .then((response) => {
                      setCourseActivities(response.data);
                    })
                    .catch((e) => console.log(e));
                } else {
                  setMessage(response.data);
                }

                setDataUpload(true);
                // setTimeout(() => {
                //   setDataUpload(false);
                // }, 2000);
              })
              .catch((e) => console.log(e));
          } else {
            toast.warn("Launcher.html is missing in scrom package!", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            e.target.value = null;
          }
        } catch (error) {
          console.error("Error reading zip file:", error);
        }
      } else {
        toast.warn("Please select zip format for SCROM!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        e.target.value = null;
      }
    }
  };

  const uploadMedia = (e) => {
    const user = AuthService.getCurrentUser();
    const filo = e.target.files[0];
    const corsid = cid;
    const userid = user.user;

    const formData = new FormData();

    formData.append("filo", filo);
    formData.append("corsid", corsid);
    formData.append("userid", userid);

    setMediaUploading(true);

    CourseService.uploadMedia(formData)
      .then((response) => {
        if (response.data == true) {
          setMediaUploading(false);
          // setMessage("Media files uploaded successfully!");

          toast.success("Media files uploaded successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          CourseService.getCourseActivity(cid)
            .then((response) => {
              setCourseActivities(response.data);
            })
            .catch((e) => console.log(e));
        } else {
          setMessage(response.data);
        }

        setDataUpload(true);
        setTimeout(() => {
          setDataUpload(false);
        }, 2000);
      })
      .catch((e) => console.log(e));
  };

  const prevPage = (e) => {
    e.preventDefault();
    prevStep();
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    if (courseActivities === false) {
      toast.warn(
        "You have not added any activities yet. Please add atleast one activity.!",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } else {
      CourseService.updateCourseActPos(courseActPos)
        .then((resp) => {
          toast.success("Acivities postition updated successfully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          nextStep();
        })
        .catch((e) => console.log(e));
    }
  };

  const setConditions = (e) => {
    e.preventDefault();
  };

  const editCourseActivity = (e, actname) => {
    e.preventDefault();
    setEditCourseAct(!editCourseAct);
    setCourseAct({
      ...courseAct,
      actid: e.currentTarget.getAttribute("data-bs-target"),
      activity_name: actname,
    });
  };

  const deleteCourseActivity = () => {
    // delete activity
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <ToastContainer />
        <div className="courseupload-area">
          <div className="row">
            <div className="col-xl-3 col-lg-3">
              <div className="upload-flex">
                <div className="upload-scrom-area">
                  <input
                    type="file"
                    id="scrom"
                    name="scrom"
                    onChange={uploadScrom}
                  />
                  <button className="upload-btn">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="16"
                        viewBox="0 0 28.121 25.217"
                      >
                        <g
                          id="upload-big-arrow"
                          transform="translate(-0.133 485.387)"
                        >
                          <path
                            id="Path_13620"
                            data-name="Path 13620"
                            d="M124.024-485.344a1.789,1.789,0,0,0-.412.181c-.088.066-1.66,1.621-3.489,3.462a34.115,34.115,0,0,0-3.407,3.561,1.163,1.163,0,0,0,.621,1.484,6.763,6.763,0,0,0,1.742.082h1.539l.016,4.413.016,4.413.165.324a1.649,1.649,0,0,0,.907.791c.286.082,4.88.082,5.165,0a1.649,1.649,0,0,0,.907-.791l.165-.324.016-4.413.016-4.413h1.539a6.763,6.763,0,0,0,1.742-.082,1.151,1.151,0,0,0,.572-1.572c-.176-.363-6.836-7-7.1-7.072A1.076,1.076,0,0,0,124.024-485.344Z"
                            transform="translate(-110.11 0)"
                          />
                          <path
                            id="Path_13621"
                            data-name="Path 13621"
                            d="M.978-266.72a1.463,1.463,0,0,0-.709.665c-.115.214-.115.264-.132,3.517A31.334,31.334,0,0,0,.208-258.7a5.987,5.987,0,0,0,4.786,4.962c.462.093,1.017.1,9.2.1s8.737-.005,9.2-.1a5.987,5.987,0,0,0,4.786-4.962,31.333,31.333,0,0,0,.071-3.841c-.016-3.253-.016-3.3-.132-3.517a1.4,1.4,0,0,0-2.511,0c-.121.214-.121.264-.148,3.654a24.571,24.571,0,0,1-.115,3.682,3.343,3.343,0,0,1-2.165,2.16l-.385.121H5.594l-.385-.121a3.343,3.343,0,0,1-2.165-2.16,24.572,24.572,0,0,1-.115-3.682c-.027-3.391-.027-3.44-.148-3.654A1.385,1.385,0,0,0,.978-266.72Z"
                            transform="translate(0 -206.535)"
                          />
                        </g>
                      </svg>
                    </span>
                    <span>Upload Scrom</span>
                  </button>
                  <div className="info-text">
                    <span>Drag & Drop SCORM Here</span>
                  </div>
                </div>
                <div className="upload-media-area">
                  <input
                    type="file"
                    id="media"
                    name="media"
                    onChange={uploadMedia}
                  />
                  <button className="upload-btn">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="16"
                        viewBox="0 0 28.121 25.217"
                      >
                        <g
                          id="upload-big-arrow"
                          transform="translate(-0.133 485.387)"
                        >
                          <path
                            id="Path_13620"
                            data-name="Path 13620"
                            d="M124.024-485.344a1.789,1.789,0,0,0-.412.181c-.088.066-1.66,1.621-3.489,3.462a34.115,34.115,0,0,0-3.407,3.561,1.163,1.163,0,0,0,.621,1.484,6.763,6.763,0,0,0,1.742.082h1.539l.016,4.413.016,4.413.165.324a1.649,1.649,0,0,0,.907.791c.286.082,4.88.082,5.165,0a1.649,1.649,0,0,0,.907-.791l.165-.324.016-4.413.016-4.413h1.539a6.763,6.763,0,0,0,1.742-.082,1.151,1.151,0,0,0,.572-1.572c-.176-.363-6.836-7-7.1-7.072A1.076,1.076,0,0,0,124.024-485.344Z"
                            transform="translate(-110.11 0)"
                          />
                          <path
                            id="Path_13621"
                            data-name="Path 13621"
                            d="M.978-266.72a1.463,1.463,0,0,0-.709.665c-.115.214-.115.264-.132,3.517A31.334,31.334,0,0,0,.208-258.7a5.987,5.987,0,0,0,4.786,4.962c.462.093,1.017.1,9.2.1s8.737-.005,9.2-.1a5.987,5.987,0,0,0,4.786-4.962,31.333,31.333,0,0,0,.071-3.841c-.016-3.253-.016-3.3-.132-3.517a1.4,1.4,0,0,0-2.511,0c-.121.214-.121.264-.148,3.654a24.571,24.571,0,0,1-.115,3.682,3.343,3.343,0,0,1-2.165,2.16l-.385.121H5.594l-.385-.121a3.343,3.343,0,0,1-2.165-2.16,24.572,24.572,0,0,1-.115-3.682c-.027-3.391-.027-3.44-.148-3.654A1.385,1.385,0,0,0,.978-266.72Z"
                            transform="translate(0 -206.535)"
                          />
                        </g>
                      </svg>
                    </span>
                    <span>Upload media</span>
                  </button>
                  <div className="info-text">
                    <span>Drag & Drop Media Here</span>
                  </div>
                </div>
                <div className="add-assessment">
                  <button className="btn">Add Assessment</button>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9">
              <div className="course-flex">
                {Array.isArray(courseActivities)
                  ? courseActivities.map((courseActivity, index) => {
                      return (
                        <div
                          className="course"
                          key={index}
                          draggable
                          onDragStart={(e) => dragStart(e, index)}
                          onDragEnter={(e) => dragEnter(e, index)}
                          onDragEnd={drop}
                        >
                          <div className="course-count">
                            <span>{index + 1}</span>
                            <span>
                              <i className="uil uil-draggabledots"></i>
                            </span>
                          </div>
                          <div className="draggable-course">
                            <div className="course-info">
                              <div className="course-name">
                                {editCourseAct ? (
                                  <>
                                    <Form.Group
                                      controlId="formBasicOrgName"
                                      className=""
                                    >
                                      <div className="input-box">
                                        <Form.Control
                                          type="text"
                                          name="activity_name"
                                          onChange={handleInputChange}
                                          value={courseAct.activity_name}
                                        />
                                        <span className="icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 20.199 20.201"
                                          >
                                            <g
                                              id="Group_11227"
                                              data-name="Group 11227"
                                              transform="translate(-2402.326 -6200.833)"
                                            >
                                              <path
                                                id="Path_11890"
                                                data-name="Path 11890"
                                                d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                                                transform="translate(752.656 5510.435)"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <path
                                                id="Path_11892"
                                                data-name="Path 11892"
                                                d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                                                transform="translate(751.221 5511.583)"
                                              />
                                            </g>
                                          </svg>
                                        </span>
                                      </div>
                                    </Form.Group>
                                  </>
                                ) : (
                                  <>
                                    <span>{courseActivity.activity_name}</span>
                                  </>
                                )}
                              </div>
                              <button
                                className="btn setcons"
                                onClick={handleShow}
                                data-bs-target={courseActivity.id}
                              >
                                View/Set Conditions
                              </button>
                            </div>
                            <div className="course-action">
                              <button
                                className="btn"
                                onClick={(e) => {
                                  editCourseActivity(
                                    e,
                                    courseActivity.activity_name
                                  );
                                }}
                                data-bs-target={courseActivity.id}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22.58 22.583"
                                >
                                  <g
                                    id="Group_11449"
                                    data-name="Group 11449"
                                    transform="translate(-2402.326 -6200.833)"
                                  >
                                    <path
                                      id="Path_11890"
                                      data-name="Path 11890"
                                      d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                      transform="translate(752.656 5510.995)"
                                      fill="none"
                                      // stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                    <path
                                      id="Path_11892"
                                      data-name="Path 11892"
                                      d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                      transform="translate(751.921 5511.583)"
                                      // fill="#fff"
                                    />
                                  </g>
                                </svg>
                              </button>
                              <button
                                className="btn"
                                onClick={handleDeleteShow}
                                data-bs-target={courseActivity.id}
                              >
                                <svg
                                  id="delete"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16.079"
                                  height="20.664"
                                  viewBox="0 0 16.079 20.664"
                                >
                                  <path
                                    id="Path_13633"
                                    data-name="Path 13633"
                                    d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                                    transform="translate(-57 512)"
                                    // fill="#fff"
                                  />
                                  <path
                                    id="Path_13634"
                                    data-name="Path 13634"
                                    d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                                    transform="translate(-83.869 402.606)"
                                    // fill="#fff"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}

                {scromUploading ? (
                  <>
                    <div className="loading load-anim">
                      <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={[
                          "#e15b64",
                          "#f47e60",
                          "#f8b26a",
                          "#abbd81",
                          "#849b87",
                        ]}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row">
          <input
            type="submit"
            className="onboard-btn-o"
            value="Back"
            onClick={prevPage}
          />
          <input type="submit" className="onboard-btn-f" value="Next" />
        </div>
      </Form>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Set Conditions</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body className="items-list">
          <CourseConditions actid={actid} cid={cid} handleClose={handleClose} />
        </Modal.Body>
      </Modal>

      <Modal show={deleteCourseAct} onHide={handleDeleteClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Course Activity</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleDeleteClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete selected Cohorts?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleDeleteClose}>
            No
          </button>
          <button className="yes" onClick={deleteCourseActivity}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadCourse;
