import React, { useState, useEffect } from "react";
import "./UserManagement.css";
import "./responsive.css";
import "./allLearnerNew.css";
import { Modal } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CourseImg from "../../../assets/images/course-placeholder.png";

import { ColorRing } from "react-loader-spinner";

import Select from "react-select";
import AdminUser from "../../../assets/images/profile.jpg";
import Filters from "./Filters";
import ActionFlex from "./ActionFlex";
import { Link } from "react-router-dom";

import "../../../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import UserService from "../../../services/user.service";
import CourseService from "../../../services/course.service";
import AuthService from "../../../services/auth.service";

const AllLearners = ({ permissions, roleid }) => {
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState([]);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [showCourses, setShowCourses] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [disableBulkDelete, setDisableBulkDelete] = useState(true);

  const [newPermissions, setNewPermissions] = useState(permissions);

  const [courseEnrollLoading, setCourseEnrollLoading] = useState(false);

  const [did, setDid] = useState("");
  const [userId, setUserId] = useState("");
  const [learners, setLearners] = useState([]);
  const [options, setOptions] = useState([]);

  const [filterLearners, setFilterLearners] = useState("");

  const [allLearners, setAllLearners] = useState("0");
  const [activeLearners, setActiveLearners] = useState("");
  const [inactiveLearners, setInactiveLearners] = useState("");

  const [unassigned, setUnassigned] = useState("0");

  const [courses, setCourses] = useState({
    selectedstudent: [],
    delid: [],
    courseid: [],
  });

  const getAllCourses = (uid) => {
    CourseService.getCourse(uid)
      .then((response) => {
        const courses = response.data;
        const filteredCourse = courses.filter(
          (course) => course.pubsts === "1"
        );

        setOptions(
          Array.isArray(filteredCourse)
            ? filteredCourse.map((course) => ({
                value: course.id,
                label: course.name,
              }))
            : null
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllLearnersData = () => {
    const user = AuthService.getCurrentUser();

    UserService.getLearners(user.user)
      .then((response) => {
        const learnersData = response.data;
        loadDatatable(learnersData);
        getLearnersCount(learnersData);
        setLearners(learnersData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadDatatable = (learnersData) => {
    if (learnersData.length > 0) {
      if (!$.fn.DataTable.isDataTable("#learners")) {
        function filterGlobal() {
          $("#learners")
            .DataTable()
            .search(
              $("#global_filter").val(),
              $("#global_regex").prop("checked"),
              $("#global_smart").prop("checked")
            )
            .draw();
        }

        function filterColumn(i) {
          $("#learners")
            .DataTable()
            .column(i)
            .search(
              $("#col" + i + "_filter").val(),
              $("#col" + i + "_regex").prop("checked"),
              $("#col" + i + "_smart").prop("checked")
            )
            .draw();
        }

        $.fn.dataTable.ext.search.pop();

        // Function to apply the custom filter
        function applyStatusFilter(status) {
          $.fn.dataTable.ext.search.pop();

          $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
            var rowData = data[1];

            if (rowData === status) {
              return true;
            }
            return false;
          });

          $("#learners").DataTable().draw();
        }

        function applyUnassignedFilter(status) {
          $.fn.dataTable.ext.search.pop();

          $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
            var rowData = data[4];
            if (rowData === status) {
              return true;
            }
            return false;
          });

          $("#learners").DataTable().draw();
        }

        function compareDates() {
          $.fn.dataTable.ext.search.pop();

          $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
            var minDate = $("#minDate").val();
            var maxDate = $("#maxDate").val();

            let date = data[2];

            if (minDate === date || (minDate <= date && date <= maxDate)) {
              return true;
            }
            return false;
          });

          $("#learners").DataTable().draw();

          // Clear the custom filter if the date input is empty
          if (!$(this).val()) {
            $.fn.dataTable.ext.search.pop();
          }
        }

        // Button click event handlers
        $("#activeButton").on("click", function () {
          applyStatusFilter("1");
        });

        $("#inactiveButton").on("click", function () {
          applyStatusFilter("0");
        });

        $("#unassignedButton").on("click", function () {
          applyUnassignedFilter("0");
        });

        $("#allButton").on("click", function () {
          // Remove the custom filter
          $.fn.dataTable.ext.search.pop();
          $("#learners").DataTable().draw();
        });

        $("#minDate, #maxDate").on("change", function () {
          compareDates();
        });

        // function checkBothInputsChanged() {
        //   if (firstInputChanged && secondInputChanged) {
        //     compareDates();
        //     firstInputChanged = false;
        //     secondInputChanged = false;
        //   }
        // }

        $("#clearFilter").on("click", function () {
          $.fn.dataTable.ext.search.pop();
          $("#learners").DataTable().draw();
          $("#minDate").val("");
          $("#maxDate").val("");
        });

        $(document).ready(function () {
          setTimeout(function () {
            $("#learners").DataTable({
              pagingType: "full_numbers",
              responsive: true,
              pageLength: 10,
              select: true,
              lengthMenu: [
                [10, 20, 30, 50, -1],
                [10, 20, 30, 50, "All"],
              ],
              columnDefs: [
                {
                  targets: 0,
                  render: function (data, type, row, meta) {
                    return type === "export" ? meta.row + 1 : data;
                  },
                  // type: "datetime-moment",
                  // render: function (data, type, full) {
                  //   return moment(data, "YYYY-MM-DD"); // Adjust the date format as needed
                  // },
                },
                {
                  targets: [1, 2, 3],
                  orderable: true,
                },
                {
                  targets: [4, 5],
                  orderable: false,
                },
              ],
            });

            $("input.global_filter").on("keyup click", function () {
              filterGlobal();
            });

            $("input.column_filter").on("keyup click", function () {
              filterColumn($(this).parents("tr").attr("data-column"));
            });
          }, 0);
        });
      }
    }
  };

  const getLearnersCount = (learnersData) => {
    setAllLearners(Array.isArray(learnersData) ? learnersData.length : null);

    setActiveLearners(
      Array.isArray(learnersData)
        ? learnersData.filter((learner) => learner.status === "1").length
        : null
    );
    setInactiveLearners(
      Array.isArray(learnersData)
        ? learnersData.filter((learner) => learner.status === "0").length
        : null
    );
    setUnassigned(
      Array.isArray(learnersData)
        ? learnersData.filter((learner) => learner.course_count === null).length
        : null
    );
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setUserId(user.user);
    getAllCourses(user.user);
    getAllLearnersData();
  }, []);

  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    setShow(true);
    setDid(e.currentTarget.getAttribute("data-bs-target"));
  };

  const handleDeleteClose = () => setDeleteSelected(false);

  const handleDeleteShow = (e) => {
    setDeleteSelected(true);
  };

  const handleCourseClose = () => {
    setShowCourses(false);
  };

  const handleShowCourses = () => {
    setShowCourses(true);
  };

  const handleOptionChange = (event) => {
    setSelectedOptions(event);
    const values = event.map((option) => option.value).toString();
    // setSelectedValues(values);
    const valSplit = values.split(",");

    if (valSplit) {
      setCourses({ ...courses, courseid: valSplit });
    } else {
      setCourses({ ...courses, courseid: [] });
    }
  };

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allLearners = learners.map((l) => l.user_id);
      const allLearnersnew = learners.map((l) => l.user_id);
      setChecked(allLearners);
      setCourses({ ...courses, delid: allLearners });
      setCourses({ ...courses, selectedstudent: allLearnersnew });

      setDisableBulkDelete(false);
    } else {
      setChecked([]);
      setCourses({ ...courses, delid: [] });
      setCourses({ ...courses, selectedstudent: [] });
      setDisableBulkDelete(true);
    }
  };

  const handleLearnerChange = (e, l) => {
    let selectedUserArr = [];
    if (e.target.checked) {
      selectedUserArr = [...checked, l.user_id];
      setChecked([...checked, l.user_id]);
      setCourses({
        ...courses,
        delid: [...checked, l.user_id],
        selectedstudent: [...checked, l.user_id],
      });
    } else {
      selectedUserArr = checked.filter((item) => item !== l.user_id);
      setChecked(checked.filter((item) => item !== l.user_id));
      setCourses({
        ...courses,
        delid: checked.filter((item) => item !== l.user_id),
        selectedstudent: checked.filter((item) => item !== l.user_id),
      });
    }

    if (selectedUserArr.length > 0) {
      setDisableBulkDelete(false);
    } else {
      setDisableBulkDelete(true);
    }
  };

  const assignCourses = (e) => {
    e.preventDefault();

    const data = {
      baps: userId,
      selectedstudent: courses.selectedstudent,
      courseid: courses.courseid,
    };

    setCourseEnrollLoading(true);

    if (data.courseid.length > 0 && data.selectedstudent.length > 0) {
      CourseService.addStudentsInCourse(data)
        .then((res) => {
          if (res.data.error) {
            setCourseEnrollLoading(false);
            toast.warn(res.data.error, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            setCourseEnrollLoading(false);
            toast.success(
              "Courses Enrolled to Selected Learners successfully!",
              {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );

            getAllLearnersData();
          }
          setChecked([]);
          setSelectedOptions([]);
        })
        .catch((e) => console.log(e));
    } else {
      if (data.courseid.length === 0) {
        toast.warn("Please select courses.!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCourseEnrollLoading(false);
      } else if (data.selectedstudent.length === 0) {
        toast.warn("Please select students.!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCourseEnrollLoading(false);
      }
    }
  };

  const deleteSelectedUser = (e) => {
    e.preventDefault();
    const data = {
      usp: userId,
      delid: courses.delid,
    };

    UserService.deleteMultipleLearners(data)
      .then((response) => {
        toast.success("Selected Learners deleted successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setChecked([]);

        setShow(false);

        UserService.getLearners(userId)
          .then((response) => {
            setLearners(response.data);
          })
          .catch((e) => {
            console.log(e);
          });

        setDeleteSelected(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteLearner = (e) => {
    e.preventDefault();
    UserService.deleteLearners(userId, did)
      .then((response) => {
        toast.success("Learner deleted successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setShow(false);
        setLearners(learners.filter((learner) => learner.user_id !== did));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [viewCourses, setViewCourses] = useState([]);

  const fetchLearnerCoursesById = (id) => {
    CourseService.getAllLearnerscourses(id).then((response) => {
      setViewCourses(response.data);
    });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-3">
            <Filters />
          </div>
          <div className="col-xl-9 col-lg-9">
            <div className="box-outer right">
              <div className="box-inner">
                <ActionFlex permissions={permissions} roleid={roleid} />
                {courseEnrollLoading ? (
                  <>
                    <div className="loading load-anim">
                      <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={[
                          "#e15b64",
                          "#f47e60",
                          "#f8b26a",
                          "#abbd81",
                          "#849b87",
                        ]}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="search-bar">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Start typing to search..."
                  />
                </div>
                <div className="act-flex">
                  <div className="permission-flex">
                    <div className="multiple-permission">
                      <div className="">
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              width: "100%",
                              border: "none",
                              outline: "none",
                              borderRadius: "10px",
                              backgroundColor: state.isFocused
                                ? "var(--dark-bg)"
                                : "var(--dark-bg)",
                            }),
                            option: (
                              baseStyles,
                              { isFocused, isSelected }
                            ) => ({
                              ...baseStyles,
                              backgroundColor: isSelected
                                ? "var(--primary-color)"
                                : isFocused
                                ? "var(--secondary-color)"
                                : "var(--dark-bg)",
                              color: isSelected
                                ? "var(--body-dark-bg)"
                                : isFocused
                                ? "var(--body-dark-bg)"
                                : "var(--whiteColor)",
                            }),
                          }}
                          // className="react-select-container"
                          // classNamePrefix="react-select"
                          onChange={handleOptionChange}
                          value={selectedOptions}
                          options={options}
                          isMulti={true}
                          issearchable="true"
                          placeholder="Quick Assign multiple courses"
                        />
                      </div>
                    </div>
                    <div className="act-permission">
                      <button onClick={assignCourses}>Assign</button>
                      {(permissions && permissions.includes("ml4")) ||
                      roleid === "1" ? (
                        <>
                          <button
                            onClick={handleDeleteShow}
                            disabled={disableBulkDelete}
                          >
                            Delete
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="user-status-flex">
                  <div className="user-status all" id="allButton">
                    <span>All</span>
                    <span>{allLearners}</span>
                  </div>
                  <div className="user-status active" id="activeButton">
                    <span>Active</span>
                    <span>{activeLearners}</span>
                  </div>
                  <div className="user-status inactive" id="inactiveButton">
                    <span>Inactive</span>
                    <span></span>
                    <span>{inactiveLearners}</span>
                  </div>
                  <div className="user-status unassigned" id="unassignedButton">
                    <span>Unassigned</span>
                    <span>{unassigned}</span>
                  </div>
                </div>
                <ToastContainer />
                <div className="admin-users">
                  <div className="table-responsive">
                    <table className="table" id="learners">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Status</th>
                          <th hidden>Enrolled Date</th>
                          <th>Organization</th>
                          <th>Courses Enrolled</th>
                          {(permissions && permissions.includes("ml3")) ||
                          (permissions && permissions.includes("ml4")) ||
                          roleid === "1" ||
                          roleid === "2" ? (
                            <>
                              <th
                                style={{ textAlign: "center", width: "50px" }}
                                className="action"
                              >
                                Action
                              </th>
                            </>
                          ) : (
                            <></>
                          )}
                          <th className="action">
                            <div className="checkbox">
                              <input
                                className="styled-checkbox"
                                id="styled-checkbox-1"
                                type="checkbox"
                                value="value1"
                                onChange={handleCheckAllChange}
                                checked={checked.length === learners.length}
                              />
                              <label htmlFor="styled-checkbox-1"></label>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(learners)
                          ? learners.map((learner) => {
                              const {
                                id,
                                user_id,
                                first_name,
                                last_name,
                                email,
                                organization,
                                course_count,
                                created_on,
                                status,
                              } = learner;
                              return (
                                <tr key={id}>
                                  <td>
                                    <div className="user-flex">
                                      <div className="user-pic">
                                        <img
                                          src={AdminUser}
                                          width="40px"
                                          alt=""
                                        />
                                      </div>
                                      <div className="user-info">
                                        <span className="name">
                                          {first_name} {last_name}
                                        </span>
                                        <span className="role">{email}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className={
                                        status === "1"
                                          ? "user-status active"
                                          : "user-status inactive"
                                      }
                                    >
                                      {status}
                                    </div>
                                  </td>
                                  <td hidden>
                                    <span>{created_on}</span>
                                  </td>
                                  <td>
                                    <span>{organization}</span>
                                  </td>
                                  <td>
                                    <span>
                                      {course_count === null
                                        ? "0"
                                        : course_count}
                                    </span>
                                  </td>
                                  {(permissions &&
                                    permissions.includes("ml3")) ||
                                  (permissions &&
                                    permissions.includes("ml4")) ||
                                  roleid === "1" ||
                                  roleid === "2" ? (
                                    <>
                                      <td>
                                        <div className="table-act">
                                          {(permissions &&
                                            permissions.includes("ml3")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button
                                                onClick={() => {
                                                  handleShowCourses();
                                                  fetchLearnerCoursesById(
                                                    user_id
                                                  );
                                                }}
                                                className="view"
                                                title="View Courses"
                                              >
                                                <span>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    id="Isolation_Mode"
                                                    data-name="Isolation Mode"
                                                    viewBox="0 0 24 24"
                                                    width="18"
                                                    height="18"
                                                  >
                                                    <path d="M23.8,11.478c-.13-.349-3.3-8.538-11.8-8.538S.326,11.129.2,11.478L0,12l.2.522c.13.349,3.3,8.538,11.8,8.538s11.674-8.189,11.8-8.538L24,12ZM12,18.085c-5.418,0-8.041-4.514-8.79-6.085C3.961,10.425,6.585,5.915,12,5.915S20.038,10.424,20.79,12C20.038,13.576,17.415,18.085,12,18.085Z" />
                                                    <circle
                                                      cx="12"
                                                      cy="12"
                                                      r="4"
                                                    />
                                                  </svg>
                                                </span>
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {(permissions &&
                                            permissions.includes("ml3")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button className="edit">
                                                <Link
                                                  to={"/EditLearner"}
                                                  state={{ id: id }}
                                                >
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="15"
                                                      height="15"
                                                      viewBox="0 0 22.58 22.583"
                                                    >
                                                      <g
                                                        id="Group_11449"
                                                        data-name="Group 11449"
                                                        transform="translate(-2402.326 -6200.833)"
                                                      >
                                                        <path
                                                          id="Path_11890"
                                                          data-name="Path 11890"
                                                          d="M1654.479,694.916h-1.273a2.534,2.534,0,0,0-1.792.744,2.507,2.507,0,0,0-.744,1.8v11.426a2.54,2.54,0,0,0,2.536,2.54h11.427a2.54,2.54,0,0,0,2.536-2.54v-1.269"
                                                          transform="translate(752.656 5510.995)"
                                                          fill="none"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                          strokeWidth="2"
                                                        />
                                                        <path
                                                          id="Path_11892"
                                                          data-name="Path 11892"
                                                          d="M1666.64,691.789l3.809,3.809m1.756-1.8a2.667,2.667,0,0,0-3.773-3.771l-10.683,10.646v3.809h3.809Z"
                                                          transform="translate(751.921 5511.583)"
                                                        />
                                                      </g>
                                                    </svg>
                                                  </span>
                                                </Link>
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {(permissions &&
                                            permissions.includes("ml4")) ||
                                          roleid === "1" ||
                                          roleid === "2" ? (
                                            <>
                                              <button
                                                onClick={handleShow}
                                                data-bs-target={user_id}
                                                className="delete"
                                              >
                                                <span>
                                                  <svg
                                                    id="delete"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="12"
                                                    height="15"
                                                    viewBox="0 0 16.079 20.664"
                                                  >
                                                    <path
                                                      id="Path_13633"
                                                      data-name="Path 13633"
                                                      d="M61.585-511.434l-.566.566H57v2.3H73.079v-2.3h-4.02l-.566-.566L67.928-512H62.151Z"
                                                      transform="translate(-57 512)"
                                                    />
                                                    <path
                                                      id="Path_13634"
                                                      data-name="Path 13634"
                                                      d="M85.008-390.9l.012,7.1.093.263a2.356,2.356,0,0,0,1.543,1.519c.214.073.444.077,5.252.077s5.038,0,5.252-.077a2.356,2.356,0,0,0,1.543-1.519l.093-.263.012-7.1.008-7.1H85Z"
                                                      transform="translate(-83.869 402.606)"
                                                    />
                                                  </svg>
                                                </span>
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </td>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <td>
                                    <div className="checkbox">
                                      <input
                                        className="styled-checkbox"
                                        id={id}
                                        type="checkbox"
                                        value="value1"
                                        checked={checked.includes(user_id)}
                                        onChange={(e) =>
                                          handleLearnerChange(e, learner)
                                        }
                                      />
                                      <label htmlFor={id}></label>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Learner</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this learner?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleClose}>
            No
          </button>
          <button className="yes" onClick={deleteLearner}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteSelected} onHide={handleDeleteClose} centered>
        <Modal.Header>
          <Modal.Title>Delete Selected Learners</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleDeleteClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete selected Learners?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="no" onClick={handleDeleteClose}>
            No
          </button>
          <button className="yes" onClick={deleteSelectedUser}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCourses} onHide={handleCourseClose} centered>
        <Modal.Header>
          <Modal.Title>Courses</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleCourseClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="list-view-course-container">
            <div className="list-container view-list-container">
              {Array.isArray(viewCourses) ? (
                viewCourses.map((course) => (
                  <>
                    <div className="list-item view-course-list-item">
                      {course.cimg ? (
                        <>
                          <img
                            className="list-course-img"
                            src={
                              "https://lmsaero.aeonsoftware.net/cit/" +
                              course.cimg
                            }
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          <img
                            className="list-course-img"
                            src={CourseImg}
                            alt=""
                          />
                        </>
                      )}
                      <div className="list-course-details view-course-details">
                        <div className="list-course-details2 view-course-details2">
                          <span className="list-course-name">
                            {`${course.coursename} -`}
                          </span>
                          <span className="list-course-cat">
                            {course.course_catename
                              ? course.course_catename
                              : "No Category"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <>
                  <div>No courses assigned yet!</div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllLearners;
