import React from "react";
import { render } from "react-dom";
import jsPDF from "jspdf";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import certificate from "../../../assets/images/certificate-bg.jpg";
import { renderToStaticMarkup } from "react-dom/server";
import { PDFDownloadLink } from "@react-pdf/renderer";
import "./certificate.css";

const styles = StyleSheet.create({
  view: {
    position: "relative",
  },
  certificate: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
  },
  logo: {
    position: "absolute",
    top: "25px",
    width: "100px",
    height: "100px",
    margin: "0 auto",
    marginBottom: "25px",
  },
  textone: {
    position: "absolute",
    top: "225px",
    left: "40%",
    transform: "translateX(-50%)",
    fontSize: "24px",
    textAlign: "center",
  },
  texttwo: {
    position: "absolute",
    top: "275px",
    left: "40%",
    transform: "translateX(-50%)",
    fontSize: "24px",
    textAlign: "center",
  },
});

const CertificatePreview = ({ cerimg }) => (
  <Document>
    <Page size="A4" style={styles.page} orientation="landscape">
      <View style={styles.view}>
        <Image src={certificate} style={styles.certificate} />
      </View>
    </Page>
  </Document>
);

const Preview = () => {
  const cerimg =
    "https://lmsaero.aeonsoftware.net/cit/uploads/images/temp1.jpg";

  return (
    <div>
      <PDFViewer width="100%" height="410px">
        <CertificatePreview cerimg={cerimg} />
      </PDFViewer>
      <PDFDownloadLink
        document={<CertificatePreview cerimg={cerimg} />}
        fileName="example.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading..." : "Download Certificate"
        }
      </PDFDownloadLink>
    </div>
  );
};

export default Preview;
