import React, { useEffect, useState, useMemo } from "react";
// import Calendar from "../../common/Calendar";
import EventService from "../../services/event.service";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import AllEvents from "../../common/AllEvents";

import Loading from "../../assets/images/Loading.gif";

import CertService from "../../services/cert.service";

import CourseImg from "../../assets/images/course-placeholder.png";

import "./learn.css";
import RecommendedCourses from "../../common/RecommendedCourses";
import CourseTimeEngagement from "./CourseTimeEngagement";
import CourseService from "../../services/course.service";
import AuthService from "../../services/auth.service";
import PathwayService from "../../services/pathway.service";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";

import Conversation from "../../common/Conversation";
import ChatBot from "../../common/ChatBot";
import Certificate from "../../assets/images/certificate.png";
import CourseOverviewChart from "./CourseOverviewChart";
import MyProgressChart from "./MyProgressChart";
import GraphService from "../../services/graph.service";

const LearnerDashboard = ({ mode, chnageMode }) => {
  const [courses, setCourses] = useState([]);
  const [courseEnrolmentData, setCourseEnrolmentData] = useState([]);
  const [courseEnrollmentFilter, setCourseEnrollmentFilter] = useState("");
  const [lastSevenDates, setLastSevenDates] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [months, setMonths] = useState([]);

  const [modeTopbar, setModeTopbar] = useState("");

  const [value, onChange] = useState(new Date());

  const [allEvents, setAllEvents] = useState([]);

  const [allCerts, setAllCerts] = useState([]);

  const [isLoadingCourses, setIsLoadingCourses] = useState(true);

  const [learneringOverviewValue, setLearneringOverviewValue] = useState([]);

  const [studentProgress, setStudentProgress] = useState({
    completed_course: "",
    inprocess_course: "",
    pending_course: "",
  });

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [chat, setChat] = useState(false);

  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const days =
      Math.floor((date - firstDayOfYear) / (24 * 60 * 60 * 1000)) + 1;
    const weekNumber = Math.ceil(days / 7);
    return weekNumber;
  }

  const today = new Date();

  const showChat = () => {
    setChat(true);
  };
  const hideChat = () => {
    setChat(false);
  };

  const getLastSevenDays = () => {
    const lastSevenDates = [];

    const lastFifteenDates = [];

    const currentDate = new Date(today);

    for (let i = 0; i < 10; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i);
      lastSevenDates.push(date);
    }

    const sortedDatesSeven = lastSevenDates.slice().sort((a, b) => {
      const dateA = a.getDate();
      const dateB = b.getDate();
      const monthA = a.getMonth();
      const monthB = b.getMonth();

      if (monthA === monthB) {
        return dateA - dateB;
      } else {
        return monthA - monthB;
      }
    });

    const formattedDates = sortedDatesSeven.map((date) => {
      const day = date.getDate().toString().padStart(2, "0");
      // const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const month = monthNames[date.getMonth()];
      return `${month} ${day}`;
    });

    setLastSevenDates(formattedDates);
    setCourseEnrolmentData(formattedDates);
  };

  const getWeeks = () => {
    const lastSevenWeeks = [];

    for (let i = 0; i < 10; i++) {
      const weekStartDate = new Date(today);
      weekStartDate.setDate(today.getDate() - i * 7);
      const weekNumber = getWeekNumber(weekStartDate);
      const formattedWeek = `Week ${weekNumber}`;

      lastSevenWeeks.push({ formattedWeek, startDate: weekStartDate });
    }

    lastSevenWeeks.sort((a, b) => {
      return a.startDate - b.startDate;
    });

    const sortedWeeks = lastSevenWeeks.map((week) => week.formattedWeek);

    setWeeks(sortedWeeks);
  };

  const getMonths = () => {
    const lastSevenMonths = [];

    for (let i = 0; i < 12; i++) {
      const monthStartDate = new Date(today);
      monthStartDate.setMonth(monthStartDate.getMonth() - i);

      lastSevenMonths.push(monthStartDate);
    }

    const sortedMonths = lastSevenMonths.slice().sort((a, b) => {
      const monthA = a.getMonth();
      const monthB = b.getMonth();
      const yearA = a.getFullYear();
      const yearB = b.getFullYear();

      if (yearA === yearB) {
        return monthA - monthB;
      } else {
        return yearA - yearB;
      }
    });

    const formattedDates = sortedMonths.map((date) => {
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();

      return `${month} ${year}`;
    });

    setMonths(formattedDates);
  };

  const filterCourseEnrollment = (e) => {
    setCourseEnrollmentFilter(e.target.value);
    if (e.target.value == 0) {
      setCourseEnrolmentData(lastSevenDates);

      GraphService.getStudentLearnOverview()
        .then((resp) => {
          setLearneringOverviewValue(resp.data.learningview15Days);
        })
        .catch((e) => console.log(e));
    } else if (e.target.value == 1) {
      setCourseEnrolmentData(weeks);
      GraphService.getStudentLearnOverview()
        .then((resp) => {
          setLearneringOverviewValue(resp.data.learningviewWeek);
        })
        .catch((e) => console.log(e));
    } else if (e.target.value == 2) {
      setCourseEnrolmentData(months);
      GraphService.getStudentLearnOverview()
        .then((resp) => {
          setLearneringOverviewValue(resp.data.learningviewMonth);
        })
        .catch((e) => console.log(e));
    }
  };

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    let newDate = `${day}-${month}-${year}`;
    return newDate;
  };

  useEffect(() => {
    getLastSevenDays();
    getMonths();
    getWeeks();

    GraphService.getStudentLearnOverview()
      .then((resp) => {
        setLearneringOverviewValue(resp.data.learningview15Days);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    const data = {
      uid: user.user,
      cid: "",
    };

    CertService.getCertBycond(data)
      .then((resp) => {
        setAllCerts(resp.data);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    GraphService.getStudentCourseProgress(user.user)
      .then((resp) => {
        setStudentProgress(resp.data);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    getLearnersCoursesData(user.user);
  }, []);

  const getLearnersCoursesData = (uid) => {
    CourseService.getAllLearnerscourses(uid)
      .then((resp) => {
        setCourses(resp.data);
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="container-fluid learner-dash">
        <div className="row">
          <div className="col-xxl-9 col-xl-9 col-lg-8">
            <div className="outer-box mb-3">
              <div className="cflex">
                <div className="heading">
                  <h1>Overview</h1>
                </div>
              </div>
              <div className="main-boxes">
                <div className="boxes1">
                  <div className="box">
                    <Link to={"/Track"}>
                      <div>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 56.79 56.788"
                          >
                            <g
                              id="check-box"
                              transform="matrix(0.966, -0.259, 0.259, 0.966, 79.761, 476.071)"
                            >
                              <path
                                id="Path_14305"
                                data-name="Path 14305"
                                d="M48.074-468.66a6.992,6.992,0,0,0-4.305,3.619c-.763,1.537-.719.229-.687,19.575l.033,17.483.24.708a7.325,7.325,0,0,0,4.556,4.5l.763.24H83.878l.763-.24A7.232,7.232,0,0,0,89.2-427.33l.24-.763V-463.3l-.24-.763a7.324,7.324,0,0,0-4.5-4.556l-.708-.24-17.548-.022L48.891-468.9Zm27.51,14.91a2.414,2.414,0,0,1,1.493,1.232,2.609,2.609,0,0,1,0,2.005c-.12.218-2.976,3.161-6.409,6.572-5.875,5.853-6.213,6.18-6.714,6.3a2.246,2.246,0,0,1-1.766-.262c-.24-.142-1.842-1.668-3.553-3.4-3.412-3.433-3.433-3.455-3.346-4.621a2.148,2.148,0,0,1,1.166-1.809,2.5,2.5,0,0,1,2.125-.033,22,22,0,0,1,2.627,2.4l2.18,2.18,5.232-5.221c4.512-4.5,5.3-5.232,5.668-5.33A1.962,1.962,0,0,1,75.584-453.75Z"
                                transform="translate(0 0)"
                                fill={
                                  mode === "1" || chnageMode === "light"
                                    ? "rgba(27, 241, 187, 1)"
                                    : "rgba(27, 241, 187, 0.7)"
                                }
                              />
                            </g>
                          </svg>
                        </span>
                        <div className="box-course-detail">
                          <span className="count">
                            {studentProgress.completed_course
                              ? studentProgress.completed_course
                              : 0}
                          </span>
                          <span className="count-info">Completed courses</span>
                        </div>
                      </div>
                      <div className="bg-icon">
                        <span>
                          <svg
                            id="check-box"
                            xmlns="http://www.w3.org/2000/svg"
                            width="110"
                            height="102"
                            viewBox="0 0 153.793 143.776"
                          >
                            <path
                              id="Path_14305"
                              data-name="Path 14305"
                              d="M17.1,1.171A23.115,23.115,0,0,0,2.8,12.426C.247,17.19.413,13.145.191,72.961L0,127.014.781,129.2a23.186,23.186,0,0,0,14.99,13.846l2.519.729L76.5,143.5l58.212-.276,2.527-.753a23.526,23.526,0,0,0,15.145-14.158l.806-2.363.3-54.424.3-54.424-.78-2.355C150.886,8.387,145.113,2.921,138.2.73L135.866,0,77.835.208,19.8.416Zm90.722,45.668a7.77,7.77,0,0,1,4.917,3.785,7.584,7.584,0,0,1-.034,6.2c-.4.676-9.894,9.82-21.307,20.421C71.865,95.434,70.742,96.45,69.082,96.829a7.841,7.841,0,0,1-5.835-.781c-.791-.434-6.063-5.127-11.692-10.458C40.332,75.028,40.26,74.961,40.569,71.354a6.718,6.718,0,0,1,3.888-5.613,8.8,8.8,0,0,1,7.029-.134c.9.4,4.343,3.349,8.646,7.373L67.3,79.685,84.694,63.461c15-13.989,17.607-16.259,18.834-16.568A6.917,6.917,0,0,1,107.817,46.839Z"
                              transform="translate(0 0)"
                            />
                          </svg>
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="box ">
                    <Link to={"/Learn"}>
                      <div>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="35"
                            viewBox="0 0 53.961 54"
                          >
                            <g id="note" transform="translate(-0.379 511.9)">
                              <path
                                id="Path_14302"
                                data-name="Path 14302"
                                d="M49.842-511.795a1.58,1.58,0,0,0-.8.75c-.084.158-.507,2.312-.94,4.8s-.823,4.645-.855,4.8c-.042.2-.011.3.084.3.433.021,31.64-5.553,31.471-5.616-.274-.106-28.166-5.152-28.451-5.141A1.813,1.813,0,0,0,49.842-511.795Z"
                                transform="translate(-41.901 0)"
                                // fill="#9fc6ff"
                                fill={
                                  mode === "1" || chnageMode === "light"
                                    ? "rgba(115, 169, 248, 1)"
                                    : "rgba(115, 169, 248, 0.7)"
                                }
                              />
                              <path
                                id="Path_14303"
                                data-name="Path 14303"
                                d="M85.251-443.319c-12.732,2.249-18.8,3.368-19.045,3.505a1.486,1.486,0,0,0-.813,1.087c-.084.517,6.545,38.037,6.8,38.46a1.536,1.536,0,0,0,1.077.771c.243.032,7-1.109,19-3.23,10.251-1.805,18.823-3.336,19.045-3.389a1.738,1.738,0,0,0,1.172-1.172c.1-.507-6.524-38.09-6.788-38.544a1.459,1.459,0,0,0-1.256-.771C104.117-446.624,97.3-445.462,85.251-443.319Zm16.057,10.61a1.58,1.58,0,0,1-.169,2.544c-.2.148-3.5.76-12.88,2.418-6.947,1.214-12.8,2.217-13,2.217A1.613,1.613,0,0,1,73.8-427.1a1.794,1.794,0,0,1,.75-1.32c.2-.116,4.033-.834,11.043-2.069C91.5-431.537,97.1-432.53,98.025-432.7,100.073-433.089,100.9-433.089,101.309-432.709Zm.845,6.081a1.573,1.573,0,0,1,.074,2.7c-.222.137-4.212.876-12.869,2.407-6.9,1.225-12.721,2.217-12.922,2.217a1.582,1.582,0,0,1-1.531-1.742,1.643,1.643,0,0,1,.9-1.214c.433-.179,24.82-4.508,25.474-4.529A2.448,2.448,0,0,1,102.153-426.628ZM95-418.879a1.575,1.575,0,0,1,.011,2.682c-.422.253-16.891,3.146-17.6,3.093a1.435,1.435,0,0,1-1.372-1.657,1.436,1.436,0,0,1,.771-1.214c.359-.19,16.5-3.083,17.314-3.1A1.587,1.587,0,0,1,95-418.879Z"
                                transform="translate(-58.15 -58.404)"
                                // fill="#9fc6ff"
                                fill={
                                  mode === "1" || chnageMode === "light"
                                    ? "rgba(115, 169, 248, 1)"
                                    : "rgba(115, 169, 248, 0.7)"
                                }
                              />
                              <path
                                id="Path_14304"
                                data-name="Path 14304"
                                d="M2.25-339.989C.909-332.367.339-328.872.382-328.64a1.622,1.622,0,0,0,.8,1.024c.253.137,7.39,1.467,7.453,1.394C8.69-326.265,4.3-351,4.245-351,4.214-351,3.316-346.049,2.25-339.989Z"
                                transform="translate(0 -143.914)"
                                // fill="#9fc6ff"
                                fill={
                                  mode === "1" || chnageMode === "light"
                                    ? "rgba(115, 169, 248, 1)"
                                    : "rgba(115, 169, 248, 0.7)"
                                }
                              />
                            </g>
                          </svg>
                        </span>
                        <div className="box-course-detail">
                          <span className="count">
                            {studentProgress.inprocess_course
                              ? studentProgress.inprocess_course
                              : 0}
                          </span>
                          <span className="count-info">
                            In Progress Courses
                          </span>
                        </div>
                      </div>
                      <div className="bg-icon">
                        <span>
                          <svg
                            id="note"
                            xmlns="http://www.w3.org/2000/svg"
                            width="115"
                            height="105"
                            viewBox="0 0 160.294 147.335"
                          >
                            <path
                              id="Path_14302"
                              data-name="Path 14302"
                              d="M55-511.612a4.561,4.561,0,0,0-2.383,2.045c-.251.432-1.505,6.308-2.791,13.106-1.317,6.769-2.446,12.674-2.54,13.106-.125.547-.031.806.251.806,1.286.058,93.988-15.151,93.486-15.324C140.2-498.16,57.347-511.929,56.5-511.9A5.8,5.8,0,0,0,55-511.612Z"
                              transform="translate(-32.535 511.9)"
                            />
                            <path
                              id="Path_14303"
                              data-name="Path 14303"
                              d="M124.384-437.644c-37.821,6.135-55.854,9.188-56.575,9.563a4.107,4.107,0,0,0-2.415,2.967c-.251,1.411,19.444,103.781,20.2,104.934a4.566,4.566,0,0,0,3.2,2.1c.721.086,20.792-3.024,56.449-8.814,30.451-4.926,55.916-9.1,56.575-9.246a4.965,4.965,0,0,0,3.481-3.2c.282-1.383-19.381-103.925-20.165-105.164a4.393,4.393,0,0,0-3.732-2.1C180.426-446.659,160.167-443.491,124.384-437.644Zm47.7,28.948c2.415,2.1,2.2,5.156-.5,6.942-.6.4-10.412,2.074-38.26,6.6-20.635,3.313-38.009,6.049-38.605,6.049a4.581,4.581,0,0,1-4.359-4.292,4.821,4.821,0,0,1,2.227-3.6c.6-.317,11.98-2.276,32.8-5.646,17.562-2.852,34.183-5.559,36.943-6.02C168.415-409.733,170.861-409.733,172.084-408.7Zm2.509,16.591a4.121,4.121,0,0,1,.22,7.374c-.659.374-12.513,2.391-38.229,6.567-20.51,3.341-37.79,6.049-38.386,6.049-2.666,0-4.955-2.391-4.547-4.753a4.542,4.542,0,0,1,2.666-3.313c1.286-.49,73.729-12.3,75.674-12.357A7.848,7.848,0,0,1,174.593-392.1ZM153.33-370.962a4.122,4.122,0,0,1,.031,7.316c-1.254.691-50.177,8.584-52.278,8.44a4.025,4.025,0,0,1-4.077-4.522,3.91,3.91,0,0,1,2.289-3.312c1.066-.519,49.017-8.411,51.432-8.468A5.054,5.054,0,0,1,153.33-370.962Z"
                              transform="translate(-45.004 465.411)"
                              //fill="rgba(255,255,255,0.07)"
                            />
                            <path
                              id="Path_14304"
                              data-name="Path 14304"
                              d="M5.937-320.957C1.954-300.161.26-290.626.386-289.993A4.508,4.508,0,0,0,2.769-287.2c.753.374,21.953,4,22.141,3.8.157-.115-12.889-67.6-13.046-67.6C11.77-351,9.1-337.491,5.937-320.957Z"
                              transform="translate(-0.379 397.346)"
                              //fill="rgba(255,255,255,0.07)"
                            />
                          </svg>
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="box">
                    <Link to={"/Learn"}>
                      <div>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="45"
                            height="45"
                            viewBox="0 0 71.031 60.862"
                          >
                            <g
                              id="time"
                              transform="matrix(0.966, -0.259, 0.259, 0.966, 93.23, 478.899)"
                            >
                              <path
                                id="Path_14300"
                                data-name="Path 14300"
                                d="M43.273-479.747a13.529,13.529,0,0,0-9.686,7.109,13.406,13.406,0,0,0-.764,10.553,13.52,13.52,0,0,0,14.865,8.87,12.953,12.953,0,0,0,3.872-1.269,10.4,10.4,0,0,0,3.211-2.279,13.07,13.07,0,0,0,2.771-3.7,13.254,13.254,0,0,0-.039-12.12,11.725,11.725,0,0,0-2.4-3.328A13.376,13.376,0,0,0,43.273-479.747Zm3.406,6.358a3.322,3.322,0,0,1,.6.609c.259.337.272.531.272,3.185,0,3.121-.013,3.147-.893,3.807a2.342,2.342,0,0,1-2.227,0c-.868-.634-.893-.738-.932-3.457a27.586,27.586,0,0,1,.039-2.9,2.24,2.24,0,0,1,1.165-1.411A2.4,2.4,0,0,1,46.678-473.389ZM46.355-463.2a2.034,2.034,0,0,1,.622,3.328,2.05,2.05,0,0,1-3.457-1.891A2.084,2.084,0,0,1,46.355-463.2Z"
                                transform="translate(0 0)"
                                fill={
                                  mode === "1" || chnageMode === "light"
                                    ? "rgba(255, 128, 233, 1)"
                                    : "rgba(255, 128, 233, 0.7)"
                                }
                              />
                              <path
                                id="Path_14301"
                                data-name="Path 14301"
                                d="M118.52-415.779c-.518.065-1.217.155-1.554.22-.725.117-.725.117-.376,1.709a15.848,15.848,0,0,1-1.321,10.036,15.91,15.91,0,0,1-6.992,6.98,15.874,15.874,0,0,1-10,1.295c-1.645-.363-1.593-.388-1.774.66a29.309,29.309,0,0,0-.181,6.151A25.014,25.014,0,0,0,107.4-370.315a30.744,30.744,0,0,0,5.516,2.732,24.914,24.914,0,0,0,13.415.8,22.994,22.994,0,0,0,5.464-1.878,24.536,24.536,0,0,0,11.68-11.68,24.751,24.751,0,0,0-3.173-26.455,24.894,24.894,0,0,0-19.385-9.116C120.113-415.9,119.038-415.844,118.52-415.779Zm3.69,10.566a3.321,3.321,0,0,1,.6.609c.259.35.272.531.311,5.9l.039,5.555,5.555.039c5.374.039,5.555.052,5.9.311a1.828,1.828,0,0,1,.893,1.735,1.828,1.828,0,0,1-.893,1.735c-.35.272-.492.272-7.329.272s-6.98,0-7.329-.272a3.529,3.529,0,0,1-.622-.622c-.259-.35-.272-.518-.311-6.98-.026-3.652,0-6.811.039-7.044a2.24,2.24,0,0,1,1.165-1.411A2.4,2.4,0,0,1,122.211-405.213Z"
                                transform="translate(-55.85 -55.746)"
                                fill={
                                  mode === "1" || chnageMode === "light"
                                    ? "rgba(255, 128, 233, 1)"
                                    : "rgba(255, 128, 233, 0.7)"
                                }
                              />
                            </g>
                          </svg>
                        </span>
                        <div className="box-course-detail">
                          <span className="count">
                            {studentProgress.pending_course
                              ? studentProgress.pending_course
                              : 0}
                          </span>
                          <span className="count-info">Pending courses</span>
                        </div>
                      </div>
                      <div className="bg-icon">
                        <span>
                          <svg
                            id="time"
                            xmlns="http://www.w3.org/2000/svg"
                            width="90"
                            height="90"
                            viewBox="0 0 135.586 132.807"
                          >
                            <path
                              id="Path_14300"
                              data-name="Path 14300"
                              d="M26.16.459A31.58,31.58,0,0,0,3.517,16.741,30.141,30.141,0,0,0,1.731,40.912C6.665,54.939,21.68,63.718,36.482,61.227a30.72,30.72,0,0,0,9.051-2.906,24.321,24.321,0,0,0,7.507-5.22,30.185,30.185,0,0,0,6.387-36.241,26.878,26.878,0,0,0-5.6-7.622A31.676,31.676,0,0,0,26.16.459Zm7.961,14.562a7.685,7.685,0,0,1,1.392,1.394c.605.771.636,1.216.636,7.3,0,7.147-.03,7.207-2.089,8.719a5.576,5.576,0,0,1-5.207,0c-2.028-1.453-2.089-1.69-2.18-7.918a61.9,61.9,0,0,1,.091-6.643,5.156,5.156,0,0,1,2.724-3.233A5.708,5.708,0,0,1,34.121,15.021Zm-.757,23.34a4.613,4.613,0,0,1,1.453,7.622,4.786,4.786,0,0,1-8.082-4.33A4.89,4.89,0,0,1,33.364,38.361Z"
                            />
                            <path
                              id="Path_14301"
                              data-name="Path 14301"
                              d="M52.085.3c-1.211.148-2.846.356-3.633.5-1.7.267-1.7.267-.878,3.915A35.635,35.635,0,0,1,44.487,27.7,36.827,36.827,0,0,1,28.14,43.688a37.8,37.8,0,0,1-23.37,2.966c-3.844-.83-3.723-.89-4.147,1.512A65.776,65.776,0,0,0,.2,62.253a57.12,57.12,0,0,0,25.882,42.173,72.481,72.481,0,0,0,12.9,6.258,59.391,59.391,0,0,0,31.361,1.839,54.515,54.515,0,0,0,12.775-4.3,56.791,56.791,0,0,0,27.3-26.751C120.2,61.6,117.35,38.261,103,20.882A58.678,58.678,0,0,0,57.685,0C55.808.033,53.3.151,52.085.3Zm8.627,24.2A7.683,7.683,0,0,1,62.1,25.894c.605.8.636,1.216.727,13.524l.091,12.723,12.986.089c12.563.089,12.986.119,13.8.712A4.163,4.163,0,0,1,91.8,56.915a4.164,4.164,0,0,1-2.089,3.974c-.817.623-1.15.623-17.134.623s-16.316,0-17.134-.623a8.169,8.169,0,0,1-1.453-1.424c-.605-.8-.636-1.186-.727-15.985-.061-8.363,0-15.6.091-16.133a5.156,5.156,0,0,1,2.724-3.233A5.708,5.708,0,0,1,60.712,24.5Z"
                              transform="translate(19.421 18.992)"
                            />
                          </svg>
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="boxes2">
                  <div className="row">
                    <div className="col-xxl-7 col-xl-7">
                      <div className="chart-graph mb-3">
                        <div className="inner-box coursegraph mb-3 newCourseChart">
                          <div className="cflex">
                            <div className="heading">
                              <h1>Learning Overview</h1>
                            </div>
                            <div className="error-req flex-end">
                              <div className="">
                                <div className="select">
                                  <select
                                    name=""
                                    id=""
                                    value={courseEnrollmentFilter}
                                    onChange={filterCourseEnrollment}
                                  >
                                    <option value="0">Day</option>
                                    <option value="1">Week</option>
                                    <option value="2">Month</option>
                                  </select>
                                  <div className="icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16.16"
                                      height="17.486"
                                      viewBox="0 0 16.16 17.486"
                                    >
                                      <g id="sort" transform="translate(0 0)">
                                        <path
                                          id="Path_11988"
                                          data-name="Path 11988"
                                          d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                          transform="translate(-49 479.938)"
                                        />
                                        <path
                                          id="Path_11989"
                                          data-name="Path 11989"
                                          d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                          transform="translate(-221.306 356.907)"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <CourseOverviewChart
                            courseEnrolmentData={courseEnrolmentData}
                            learneringOverviewValue={learneringOverviewValue}
                            mode={mode}
                            chnageMode={chnageMode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-5 col-xl-5">
                      <div className="chart-graph pieChart mb-1">
                        <MyProgressChart />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <div className="inner-box active-courses">
                    <div className="cflex flex-center">
                      <div className="heading">
                        <h1>Achievements and Certificates</h1>
                      </div>
                    </div>
                    <div
                      className={allCerts.length > 0 ? "certificate-grid" : ""}
                    >
                      {Array.isArray(allCerts) ? (
                        allCerts.map((allCert, index) => {
                          return (
                            <div className="certificate" key={index}>
                              <div className="cer-img">
                                <img src={Certificate} alt="" />
                              </div>
                              <div className="cer-content">
                                <span className="coursename">
                                  {allCert.course_name}
                                </span>
                                <span>issued on {allCert.awarded_on}</span>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <>
                          <div className="loading">
                            <h1>No Certificates Received yet</h1>
                            <img src={Loading} width="200px" alt="" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <div className="inner-box active-courses">
                    <div className="cflex flex-center">
                      <div className="heading">
                        <h1>Active Courses</h1>
                      </div>
                    </div>
                    {courses.length > 0 ? (
                      <>
                        <div className="course-lists course">
                          {Array.isArray(courses)
                            ? courses.map((course) => (
                                <div key={course.corsid}>
                                  {course.compsts === "0" ? (
                                    <>
                                      <div className="course-item">
                                        <div className="course-info">
                                          <div className="course-img">
                                            {course.cimg ? (
                                              <>
                                                <img
                                                  src={
                                                    "https://lmsaero.aeonsoftware.net/cit/" +
                                                    course.cimg
                                                  }
                                                  alt=""
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img src={CourseImg} alt="" />
                                              </>
                                            )}
                                          </div>
                                          <div className="cinf-flex">
                                            <div className="cinfo">
                                              <span className="cname">
                                                {course.coursename}
                                              </span>
                                              {/* <span className="catname">{course.category_id}</span> */}
                                              <span className="catname">
                                                {course.course_catename}
                                              </span>
                                            </div>
                                            <div className="range">
                                              <ProgressBar
                                                now={
                                                  course.compsts === "1"
                                                    ? "100"
                                                    : `${course.compercent}`
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="course-act">
                                          <button>
                                            <Link
                                              to="/Course"
                                              state={{ cid: course.corsid }}
                                            >
                                              {course.compercent > 0 &&
                                              course.compercent < 100 ? (
                                                <>Finish Now</>
                                              ) : course.compsts === "1" ? (
                                                <>View Course</>
                                              ) : (
                                                <>Start Now</>
                                              )}
                                            </Link>
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ))
                            : null}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="loading">
                          <h1>No Courses Assigned yet</h1>
                          <img src={Loading} width="200px" alt="" />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="outer-box learn">
              <div className="cflex">
                <div className="heading">
                  <h1>Recommended Courses</h1>
                </div>
              </div>
              <RecommendedCourses />
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-4">
            <div className="outer-box mb-4">
              <div className="cflex">
                <div className="heading">
                  <h1>Schedule and Submissions</h1>
                </div>
              </div>
              <div className="flex-cal">
                <Calendar onChange={onChange} value={value} />
                <AllEvents />
              </div>
            </div>
            <div className="outer-box chat">
              <div className="cflex">
                <div className="heading">
                  <h1>Conversation</h1>
                </div>
              </div>
              <Conversation showChat={showChat} />
            </div>
            <ChatBot chat={chat} hideChat={hideChat} />
          </div>
        </div>
      </div>
    </>
  );
};
export default LearnerDashboard;
